import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';

import { IDS_TOAST_DATA, IdsToastAction, IdsToastActions, IdsToastLabel, IdsToastRef } from '../../cdk/toast';
import { IdsBadge } from '../badge/badge.component';
import { IdsIcon } from '../icon/icon.component';
import { NotificationData } from './notification';

@Component({
  selector: 'ids-notification',
  standalone: true,
  imports: [NgClass, IdsIcon, IdsBadge, IdsToastLabel, IdsToastActions, IdsToastAction],
  template: `
    <div class="flex rounded border-2 bg-white" [ngClass]="data.color?.border">
      <div class="flex items-center px-2" [ngClass]="data.color?.background">
        <ids-badge badgeColor="white">
          <i idsIcon [icon]="data.icon" [ngClass]="data.color?.foreground"></i>
        </ids-badge>
      </div>
      <div class="flex w-[300px] flex-col px-2 py-1 md:w-[564px]">
        <div class="flex justify-between">
          <span idsToastLabel class="text-base font-medium text-[#141414]">{{ data.label }}</span>
          <span idsToastActions>
            <button idsToastAction (click)="toastRef.dismissWithAction()">
              <i idsIcon icon="close" class="text-text-secondary-light"></i>
            </button>
          </span>
        </div>
        <ul class="text-text-secondary-light ml-6 text-sm">
          @for (message of data.messages; track message) {
            <li class="list-disc">{{ message }}</li>
          }
        </ul>
      </div>
    </div>
  `,
})
export class IdsNotificationComponent {
  /** A reference to the currently displayed toast. */
  readonly toastRef = inject(IdsToastRef);

  /** The data to display in the toast container. */
  readonly data: NotificationData = inject(IDS_TOAST_DATA);
}
