import { ChangeDetectionStrategy, Component, inject, model, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  IdsBadge,
  IdsButton,
  IdsCard,
  IdsCardContent,
  IdsCardFooter,
  IdsCardHeader,
  IdsCardHeaderActionGroup,
  IdsCardHeaderActions,
  IdsCardTitle,
  IdsIcon,
} from '@abbadox-monorepo/shared-ui';

import { KioskDialogData } from '../dialog-shell/dialog-shell.component';

export const enum LOGOUT_DIALOG_TRIGGERS {
  CLOSE_ICON = 'Icon',
  CLOSE_BUTTON = 'Button',
  NAVIGATE_BUTTON = 'Navigate Button',
  TIMEOUT = 'Timeout',
}

@Component({
  selector: 'kiosk-logout-dialog',
  standalone: true,
  imports: [
    IdsCard,
    IdsCardHeader,
    IdsBadge,
    IdsIcon,
    IdsCardTitle,
    IdsCardHeaderActions,
    IdsCardHeaderActionGroup,
    IdsCardContent,
    IdsIcon,
    IdsButton,
    IdsCardFooter,
  ],
  template: `
    <ids-card tier-1>
      <ids-card-header>
        <ids-badge class="bg-alert">
          <i idsIcon icon="exclamation-alert" color="white"></i>
        </ids-badge>
        <ids-card-title>{{ title() }}</ids-card-title>
        <ids-card-header-actions>
          <ids-card-header-actions-group>
            <button (click)="handleCloseClick()">
              <i idsIcon icon="close" color="var(--color-scheme-text-secondary)"></i>
            </button>
          </ids-card-header-actions-group>
        </ids-card-header-actions>
      </ids-card-header>
      <ids-card-content class="flex flex-col gap-6">
        <ids-card tier-2 ids-card-outlined ids-card-color-border class="!border-t-alert">
          <ids-card-content>
            <p class="message-line">Are you sure you want to log out?</p>
          </ids-card-content>
        </ids-card>
      </ids-card-content>

      <ids-card-footer class="dialog-actions-footer">
        <button ids-adc-button color="secondary" (click)="handleCloseClickFromButton()">Go Back</button>
        <button ids-adc-button color="primary" (click)="handleNavigateToLoginClickFromButton()">Log Out</button>
      </ids-card-footer>
    </ids-card>
  `,
  styleUrls: ['./logout-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskLogoutDialog {
  readonly dialogRef = inject(MatDialogRef);
  readonly data = inject<KioskDialogData>(MAT_DIALOG_DATA);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);

  handleCloseClick() {
    this.dialogRef.close(LOGOUT_DIALOG_TRIGGERS.CLOSE_ICON);
  }

  handleCloseClickFromButton() {
    this.dialogRef.close(LOGOUT_DIALOG_TRIGGERS.CLOSE_BUTTON);
  }

  handleNavigateToLoginClickFromButton() {
    this.dialogRef.close(LOGOUT_DIALOG_TRIGGERS.NAVIGATE_BUTTON);
  }

  returnToHome() {
    this.dialogRef.close(LOGOUT_DIALOG_TRIGGERS.TIMEOUT);
  }
}
