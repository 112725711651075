import { Component, input, output, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { PushPipe } from '@ngrx/component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

import { IdsCard, IdsCardContent } from '@abbadox-monorepo/shared-ui';

@Component({
  selector: 'kiosk-comments-form',
  standalone: true,
  imports: [PushPipe, ReactiveFormsModule, IdsCard, IdsCardContent],
  template: `
    <ids-card tier-3 ids-card-outlined ids-card-color-border [style.border-top-color]="color()">
      <ids-card-content>
        <h3>Any comments for the front desk?</h3>
        <div class="comments-section">
          <span>Comments</span>
          <textarea name="comments" id="appointment-comments" [formControl]="comment"></textarea>
          @if (commentValueChanges$ | ngrxPush) {}
        </div>
      </ids-card-content>
    </ids-card>
  `,
  styleUrls: ['./comments-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KioskCommentsForm {
  color = input<string>();
  updateComments = output<string>();

  readonly comment = new FormControl('');

  readonly commentValueChanges$ = this.comment.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    tap((value) => {
      this.updateComments.emit(value ?? '');
    }),
  );
}
