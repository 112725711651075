import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import {
  IdsBadge,
  IdsCard,
  IdsCardContent,
  IdsCardHeader,
  IdsCardHeaderActionGroup,
  IdsCardHeaderActions,
  IdsCardTitle,
  IdsIcon,
} from '@abbadox-monorepo/shared-ui';
import { IconNames } from '@abbadox-monorepo/shared-ui/lib/components/icon';

export interface KioskDialogData {
  title: string;
  iconName?: IconNames;
}

@Component({
  selector: 'kiosk-dialog-shell',
  standalone: true,
  imports: [
    IdsCard,
    IdsCardHeader,
    IdsBadge,
    IdsIcon,
    IdsCardTitle,
    IdsCardHeaderActions,
    IdsCardHeaderActionGroup,
    IdsCardContent,
  ],
  template: `
    <ids-card tier-1>
      <ids-card-header>
        <ids-badge class="bg-alert">
          <i idsIcon icon="exclamation-alert" color="white"></i>
        </ids-badge>
        <ids-card-title>{{ title() }}</ids-card-title>
        <ids-card-header-actions>
          <ids-card-header-actions-group>
            <button (click)="closeDialog()">
              <i idsIcon icon="close" color="var(--color-scheme-text-secondary)"></i>
            </button>
          </ids-card-header-actions-group>
        </ids-card-header-actions>
      </ids-card-header>
      <ids-card-content class="flex flex-col gap-6">
        <ng-content></ng-content>
      </ids-card-content>
    </ids-card>
  `,
  styleUrls: ['./dialog-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskDialogShell {
  readonly title = input('');
  readonly icon = input('exclamation-circle');

  readonly closeClick = output();

  closeDialog() {
    this.closeClick.emit();
  }
}
