import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterLink } from '@angular/router';

import { IdsButton, IdsCard, IdsCardContent, IdsCardHeader, IdsCardTitle } from '@abbadox-monorepo/shared-ui';

@Component({
  selector: 'kiosk-not-found-page',
  standalone: true,
  imports: [IdsButton, RouterLink, IdsCard, IdsCardHeader, IdsCardTitle, IdsCardContent],
  template: `
    <ids-card tier-1 ids-card-raised>
      <ids-card-header>
        <ids-card-title>404: Not Found</ids-card-title>
      </ids-card-header>
      <ids-card-content>
        <p>Hey! It looks like this page doesn't exist yet.</p>
        <button class="h-[3.75rem] !text-[36px]" ids-adc-button ids-outline-button color="primary" routerLink="/">
          Take Me Home
        </button>
      </ids-card-content>
    </ids-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPage {}
