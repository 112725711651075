import { Component, input } from '@angular/core';

import { IdsIcon } from '@abbadox-monorepo/shared-ui';

@Component({
  selector: 'kiosk-tier-one-card-footer-notification',
  standalone: true,
  imports: [IdsIcon],
  template: `
    <div class="flex items-center gap-3">
      <i idsIcon icon="info-circle" color="var(--color-scheme-primary)"></i>
      <p class="text-text-tertiary text-xs md:text-xl md:leading-6">{{ notification() }}</p>
    </div>
  `,
  styleUrls: ['./tier-one-card-footer-notification.component.scss'],
})
export class KioskTierOneCardFooterNotification {
  readonly notification = input.required<string>();
}
