import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

import { TitleCasePipe } from '@abbadox-monorepo/shared-ui';
import { RadialProgressStepperComponent } from '@abbadox-monorepo/shared-ui/lib/components/radial-progress-stepper/radial-progress-stepper.component';

@Component({
  selector: 'kiosk-header',
  standalone: true,
  imports: [NgClass, RadialProgressStepperComponent, TitleCasePipe],
  template: `
    <header
      class="header-drop-shadow-light fixed z-10 grid h-[58px] w-full max-w-full grid-cols-2 grid-rows-1 items-center bg-white p-2 px-3 md:h-[84px] md:grid-cols-3"
    >
      @if (logo()) {
        <img
          [src]="logo()"
          class="col-start-1 row-start-1 h-10 md:block md:h-[3.25rem]"
          [ngClass]="{ block: !stepTitle() && workflowHome(), hidden: stepTitle() && !workflowHome() }"
          alt="Account Logo"
        />
      }

      @if (percentage()) {
        <ids-radial-progress-stepper
          class="col-start-3 row-start-1 flex justify-center md:col-start-2"
          [progress]="percentage()"
        ></ids-radial-progress-stepper>
      }

      <div class="col-start-1 row-start-1 flex flex-col whitespace-nowrap md:col-start-3 md:items-end">
        <span class="text-lg font-medium leading-6 text-[#141414] md:text-[32px] md:leading-[38px]">{{
          stepTitle() | titlecase
        }}</span>
        <span class="text-base leading-5 text-[#787885] md:text-2xl md:leading-7">{{ stepName() }}</span>
      </div>
    </header>
  `,
})
export class KioskHeader {
  logo = input<string | null | undefined>('');
  stepTitle = input.required<string>();
  stepName = input.required<string>();
  percentage = input.required<number>();
  workflowHome = input.required<boolean>();
}
