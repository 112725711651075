import { Directive, HostListener, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[idsLastNameMask]',
  exportAs: 'idsLastNameMask',
  standalone: true,
})
export class IdsLastNameMask {
  public ngControl = inject(NgControl);

  @HostListener('ngModelChange', ['$event'])
  onKeydown(event: string) {
    this.onInputChange(event);
  }

  /**
   * Validates the last name input value.
   *
   * @param event the input value
   */
  onInputChange(event: string) {
    // Remove leading and trailing spaces
    let newVal = event.replace(/^\s+$/g, '');

    newVal = this.validateLastName(newVal);
    this.ngControl.valueAccessor?.writeValue(newVal);
  }

  private validateLastName(value: string): string {
    // Remove invalid characters
    if (!/^[A-Za-z' -]*$/.test(value)) {
      return value.replace(/[^A-Za-z' -]/g, '');
    }

    return value;
  }
}
