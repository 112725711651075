import { ChangeDetectionStrategy, Component, inject, model, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IdsButton, IdsCard, IdsCardContent } from '@abbadox-monorepo/shared-ui';

import { KioskPatientCheckinFailedDialogData } from '../checkin-failed/checkin-failed-dialog.component';
import { KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export const enum PATIENT_NOT_FOUND_DIALOG_TRIGGERS {
  CLOSE_ICON = 'Icon',
  CLOSE_BUTTON = 'Button',
  NAVIGATE_TO_NEW_PATIENT_FORM = 'navigateToNewPatientForm',
}

@Component({
  selector: 'kiosk-patient-not-found-dialog',
  standalone: true,
  imports: [KioskDialogShell, IdsCard, IdsCardContent, IdsButton],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClickFromIcon()">
      <ids-card tier-2 ids-card-outlined ids-card-color-border class="!border-t-alert">
        <ids-card-content>
          <p class="message-line">We did not find an existing patient record with the information you provided.</p>
          <p class="message-line">
            If you think you entered your Date of Birth or Last Name incorrectly, please try again or speak to the front
            desk.
          </p>
          <p class="message-line">Otherwise proceed as new patient.</p>
          <div class="dialog-actions-footer">
            <button ids-adc-button color="tertiary" (click)="handleTryAgainClickFromButton()">Try Again</button>
            <button ids-adc-button color="secondary" (click)="handleNewPatientClickFromButton()">
              I'm a New Patient
            </button>
          </div>
        </ids-card-content>
      </ids-card>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./patient-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskPatientNotFoundDialog {
  readonly dialogRef = inject(MatDialogRef);
  readonly data = inject<KioskPatientCheckinFailedDialogData>(MAT_DIALOG_DATA);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);

  handleCloseClickFromIcon() {
    this.dialogRef.close(PATIENT_NOT_FOUND_DIALOG_TRIGGERS.CLOSE_ICON);
  }

  handleTryAgainClickFromButton() {
    this.dialogRef.close(PATIENT_NOT_FOUND_DIALOG_TRIGGERS.CLOSE_BUTTON);
  }

  handleNewPatientClickFromButton() {
    this.dialogRef.close(PATIENT_NOT_FOUND_DIALOG_TRIGGERS.NAVIGATE_TO_NEW_PATIENT_FORM);
  }
}
