import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecase',
  standalone: true,
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return '';

    return value
      .split(/([\s'-])/) // Keep delimiters: space, hyphen, and apostrophe
      .map((word) => (/[a-zA-Z]/.exec(word) ? this.capitalize(word) : word))
      .join('');
  }

  private capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
}
