import { ChangeDetectionStrategy, Component, Directive, ElementRef, ViewEncapsulation } from '@angular/core';

/** List of classes to add to buttons instances based on host attribute selector. */
const HOST_SELECTOR_MIDS_CLASS_PAIR: { attribute: string; variantClasses: string[] }[] = [
  {
    attribute: 'ids-card',
    variantClasses: ['ids-card'],
  },
  /** The following attributes are the card's available variants. */
  {
    attribute: 'tier-1',
    variantClasses: ['ids-card', 'ids-card-tier-1'],
  },
  {
    attribute: 'tier-2',
    variantClasses: ['ids-card', 'ids-card-tier-2'],
  },
  {
    attribute: 'tier-3',
    variantClasses: ['ids-card', 'ids-card-tier-3'],
  },
  {
    attribute: 'ids-card-raised',
    variantClasses: ['ids-card', 'ids-card-raised'],
  },
  {
    attribute: 'ids-card-outlined',
    variantClasses: ['ids-card', 'ids-card-outlined'],
  },
  {
    attribute: 'ids-card-color-border',
    variantClasses: ['ids-card', 'ids-card-color-border'],
  },
];

@Component({
  selector: `
    ids-card, ids-card[tier-1],
    ids-card[tier-2], ids-card[tier-3],
    ids-card[ids-card-raised], ids-card[ids-card-outlined]
  `,
  template: ` <ng-content></ng-content> `,
  styleUrl: './card.component.scss',
  host: {
    '[class.ids-card-base]': 'true',
  },
  exportAs: 'idsCard',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdsCard {
  constructor(public _elementRef: ElementRef) {
    const element = _elementRef.nativeElement;
    const classList = (element as HTMLElement).classList;

    // For each of the variant selectors that is present in the button's host
    // attributes, add the correct corresponding variant classes.
    for (const { attribute, variantClasses } of HOST_SELECTOR_MIDS_CLASS_PAIR) {
      if (element.hasAttribute(attribute)) {
        classList.add(...variantClasses);
      }
    }
  }
}

/**
 * Title of a card, intended for use within `<ids-card>`. This component is an optional
 * convenience for one variety of card title; any custom title element may be used in its place.
 *
 * IdsCardTitle provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: `ids-card-title, [ids-card-title], [idsCardTitle]`,
  host: { class: 'ids-card-title' },
  standalone: true,
})
export class IdsCardTitle {}

/**
 * Sub-title of a card, intended for use within `<ids-card>` beneath a `<ids-card-title>`. This
 * component is an optional convenience for use with other convenience elements, such as
 * `<ids-card-title>`.
 *
 * IdsCardSubtitle provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: `ids-card-subtitle, [ids-card-subtitle], [IdsCardSubtitle]`,
  host: { class: 'ids-card-subtitle' },
  standalone: true,
})
export class IdsCardSubtitle {}

/**
 * Header actions group of a card, intended for use within `<ids-card>` with `<ids-card-header-actions>`. This
 * component is an optional convenience for use with other convenience elements, such as
 * `<ids-card-title>`.
 *
 * IdsCardHeaderActionGroup provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: `ids-card-header-actions-group, [ids-card-header-actions-group], [IdsCardHeaderActionsGroup]`,
  host: { class: 'ids-card-header-actions-group' },
  standalone: true,
})
export class IdsCardHeaderActionGroup {}

/**
 * Header actions of a card, intended for use within `<ids-card>` with `<ids-card-header-actions>`. This
 * component is an optional convenience for use with other convenience elements, such as
 * `<ids-card-title>`.
 *
 * IdsCardHeaderActions provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: `ids-card-header-actions, [ids-card-header-actions], [IdsCardHeaderActions]`,
  host: { class: 'ids-card-header-actions' },
  standalone: true,
})
export class IdsCardHeaderActions {}

/**
 * Content of a card, intended for use within `<ids-card>`. This component is an optional
 * convenience for use with other convenience elements, such as `<ids-card-title>`; any custom
 * content block element may be used in its place.
 *
 * IdsCardContent provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: 'ids-card-content',
  host: { class: 'ids-card-content' },
  standalone: true,
})
export class IdsCardContent {}

/**
 * Header region of a card, intended for use within `<ids-card>`. This header captures
 * a card title, subtitle, and icon badge.  This component is an optional convenience for use with
 * other convenience elements, such as `<ids-card-footer>`; any custom header block element may be
 * used in its place.
 *
 * IdsCardHeader provides no behaviors, instead serving as a purely visual treatment.
 */
@Component({
  selector: 'ids-card-header',
  host: { class: 'ids-card-header' },
  template: `
    <ng-content select="ids-badge"></ng-content>
    <div class="ids-card-header-text">
      <ng-content
        select="ids-card-title, ids-card-subtitle,
        [ids-card-title], [ids-card-subtitle],
        [idsCardTitle], [idsCardSubtitle]"
      ></ng-content>
    </div>
    <ng-content select="ids-card-header-actions"></ng-content>
    <ng-content></ng-content>
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdsCardHeader {}

/**
 * Footer area a card, intended for use within `<ids-card>`.
 * This component is an optional convenience for use with other convenience elements, such as
 * `<ids-card-content>`; any custom footer block element may be used in its place.
 *
 * IdsCardFooter provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: 'ids-card-footer',
  host: { class: 'ids-card-footer' },
  standalone: true,
})
export class IdsCardFooter {}
