import { ChangeDetectionStrategy, Component, inject, model, signal, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PushPipe } from '@ngrx/component';
import { filter, of, switchMap, tap, timer } from 'rxjs';

import { INTERVAL_TO_EMIT_IN_MILLISECONDS, STARTING_VALUE_TO_EMIT } from '@abbadox-monorepo/kiosk-core-constants';
import { IdsButton, IdsCard, IdsCardContent, IdsProgressBar } from '@abbadox-monorepo/shared-ui';

import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export const enum PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS {
  CLOSE_ICON = 'Icon',
  CLOSE_BUTTON = 'Button',
  TIMEOUT = 'Timeout',
}

export interface KioskSessionTimeoutDialogData extends KioskDialogData {
  maxTimeout: number;
  sessionTimeout: number;
}

@Component({
  selector: 'kiosk-patient-session-timeout-dialog',
  standalone: true,
  imports: [PushPipe, KioskDialogShell, IdsCard, IdsCardContent, IdsButton, IdsProgressBar],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClickFromIcon()">
      <ids-card tier-2 ids-card-outlined ids-card-color-border class="!border-t-alert">
        <ids-card-content>
          <p class="message-line">
            This session will automatically time out in
            @if (timer$ | ngrxPush) {}
            <span>{{ timerRemaining() }} seconds due to inactivity.</span>
          </p>
          <ids-progress-bar mode="determinate" [value]="progress()"></ids-progress-bar>
          <div class="dialog-actions-footer">
            <button ids-adc-button color="secondary" (click)="handleCloseClickFromButton()">Need more time</button>
          </div>
        </ids-card-content>
      </ids-card>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./patient-session-timeout-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskPatientSessionTimeoutDialog {
  readonly dialogRef = inject(MatDialogRef);
  readonly data = inject<KioskSessionTimeoutDialogData>(MAT_DIALOG_DATA);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);
  readonly maxTimeout = model(this.data.maxTimeout);
  readonly sessionTimeout = model(this.data.sessionTimeout);

  /** Watcher for timer updates. */
  readonly timerRemaining = signal(0);
  readonly progress = signal(0);

  readonly timer$ = of(this.sessionTimeout()).pipe(
    switchMap((maxTimeout) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        tap((countdown) => {
          this.timerRemaining.update(() => maxTimeout - countdown);
          this.progress.update(() => Math.ceil((countdown / maxTimeout) * 100));
        }),
        filter(() => this.progress() === 100),
        tap(() => this.returnToHome()),
      ),
    ),
  );

  handleCloseClickFromIcon() {
    this.dialogRef.close(PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS.CLOSE_ICON);
  }

  handleCloseClickFromButton() {
    this.dialogRef.close(PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS.CLOSE_BUTTON);
  }

  returnToHome() {
    this.dialogRef.close(PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS.TIMEOUT);
  }
}
