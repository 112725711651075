export const enum ICON_NAMES {
  'contact',
  'money',
  'referral-doctor',
  'comment',
  'user',
  'clipboard-user',
  'payer',
  'location',
  'location-alt',
  'paperclip',
  'clipboard',
  'files-medical',
  'self-pay',
  'wheelchair',
  'birthday',
  'calendar',
  'calendar-solid',
  'calendar-day',
  'file-pdf-solid',
  'calendar-cancel',
  'field',
  'ellipsis',
  'edit',
  'confirm-appointment',
  'prescription',
  'x-ray',
  'phone',
  'business',
  'verified',
  'no-verified',
  'stat',
  'times-circle',
  'filter',
  'cancel',
  'save',
  'undo',
  'view',
  'print',
  'external-link',
  'link',
  'square-comment',
  'far-comment',
  'fas-comment-lines',
  'fas-comment-exclamation',
  'fas-comments',
  'angle-double-left',
  'angle-left',
  'angle-right',
  'angle-double-right',
  'sort',
  'angle-down',
  'bookmark',
  'table',
  'bars',
  'regular-edit',
  'home-alt',
  'regular-user',
  'question-circle',
  'check',
  'trash-alt',
  'list-ul',
  'plus',
  'search',
  'cloud-upload-alt',
  'history',
  'female',
  'folder-open',
  'level-up',
  'girl',
  'switch',
  'cog',
  'stamp',
  'clipboard-plus',
  'clock',
  'clock-solid',
  'circle',
  'laptop-medical',
  'file-signature',
  'file-pdf',
  'calendar-exclamation',
  'books',
  'addressbooks',
  'exclamation-circle',
  'grip-vertical',
  'wallet',
  'thumbtack',
  'exchange',
  'comment-lines',
  'exclamation-alert',
  'balance-right',
  'edit-fa',
  'invoice-dollar',
  'fax',
  'share',
  'file-excel',
  'receipt',
  'user-cog',
  'user-clock',
  'upload',
  'download',
  'key',
  'unlink',
  'bell',
  'sms',
  'group',
  'arrow-down',
  'file',
  'arrow-up',
  'arrow-right',
  'arrow-left',
  'sync',
  'redo',
  'file-signature-solid',
  'copy',
  'fas-copy',
  'angle-up',
  'chevron-up',
  'chevron-down',
  'chevron-double-down',
  'chevron-double-up',
  'error-triangle-solid',
  'user-md',
  'note',
  'info-circle',
  'lungs',
  'gauge-low',
  'gauge-normal',
  'gauge-high',
  'mailbox-flag-up',
  'info',
  'credit-card',
  'close',
  'clipboard-checklist',
  'clipboard-checklist-outline',
  'x-ray-regular',
  'scanner-device',
  'book-open',
  'id-card',
  '',
}

/** Registered Icon names. */
export type IconNames = keyof typeof ICON_NAMES | undefined;

/** Font Awsome icon styles. */
type IconPrefix = 'fas' | 'fa-solid' | 'far' | 'fa-regular' | 'fal';

/** Font Awsome icon style prefix and icon name pair. */
type IconProp = [IconPrefix, string];

/* Icon Registry */
export const IconsMap = new Map<IconNames, IconProp>([
  // Solid
  ['referral-doctor', ['fas', 'fa-user-md']], // user-md
  ['comment', ['fas', 'fa-comment']],
  ['user', ['fas', 'fa-user']],
  ['clipboard-user', ['fas', 'fa-clipboard-user']],
  ['payer', ['fas', 'fa-file-invoice-dollar']],
  ['location', ['fas', 'fa-map-marker-alt']],
  ['clipboard', ['fas', 'fa-clipboard-list']],
  ['files-medical', ['fas', 'fa-files-medical']],
  ['birthday', ['fas', 'fa-birthday-cake']],
  ['calendar-solid', ['fas', 'fa-calendar-alt']],
  ['file-pdf-solid', ['fas', 'fa-file-pdf']],
  ['field', ['fas', 'fa-folder']],
  ['edit', ['fas', 'fa-pencil']],
  ['x-ray', ['fas', 'fa-x-ray']],
  ['phone', ['fas', 'fa-phone-alt']],
  ['link', ['fas', 'fa-link']],
  ['fas-comments', ['fas', 'fa-comments']],
  ['angle-double-left', ['fas', 'fa-angle-double-left']],
  ['angle-left', ['fas', 'fa-angle-left']],
  ['sort', ['fas', 'fa-sort']],
  ['cloud-upload-alt', ['fas', 'fa-cloud-upload-alt']],
  ['female', ['fas', 'fa-female']], // girl
  ['folder-open', ['fas', 'fa-folder-open']],
  ['stamp', ['fas', 'fa-stamp']],
  ['clock-solid', ['fas', 'fa-clock']],
  ['circle', ['fas', 'fa-circle']],
  ['exclamation-circle', ['fas', 'fa-exclamation-circle']],
  ['grip-vertical', ['fas', 'fa-grip-vertical']],
  ['wallet', ['fas', 'fa-wallet']],
  ['exclamation-alert', ['fas', 'fa-exclamation']],
  ['balance-right', ['fas', 'fa-balance-scale-right']],
  ['fax', ['fas', 'fa-fax']],
  ['share', ['fas', 'fa-share']],
  ['user-cog', ['fas', 'fa-user-cog']],
  ['user-clock', ['fas', 'fa-user-clock']],
  ['upload', ['fas', 'fa-upload']],
  ['download', ['fas', 'fa-download']],
  ['key', ['fas', 'fa-key']],
  ['unlink', ['fas', 'fa-unlink']],
  ['bell', ['fas', 'fa-bell']],
  ['group', ['fas', 'fa-layer-group']],
  ['file', ['fas', 'fa-file']],
  ['file-signature-solid', ['fas', 'fa-file-signature']],
  ['fas-copy', ['fas', 'fa-copy']],
  ['error-triangle-solid', ['fas', 'fa-exclamation-triangle']],
  ['lungs', ['fas', 'fa-lungs']],
  ['fas-comment-lines', ['fas', 'fa-comment-lines']],
  ['fas-comment-exclamation', ['fas', 'fa-comment-exclamation']],
  ['books', ['fas', 'fa-books']],
  ['gauge-low', ['fas', 'fa-gauge-simple-min']],
  ['gauge-normal', ['fas', 'fa-gauge-simple']],
  ['gauge-high', ['fas', 'fa-gauge-simple-max']],
  ['mailbox-flag-up', ['fas', 'fa-mailbox-flag-up']],
  ['clipboard-checklist', ['fa-solid', 'fa-clipboard-list-check']],
  ['scanner-device', ['fa-solid', 'fa-scanner-image']],
  ['list-ul', ['fa-solid', 'fa-list-ul']],
  ['id-card', ['fa-solid', 'fa-id-card']],
  ['plus', ['fa-solid', 'fa-plus']],

  // Regular
  ['contact', ['far', 'fa-address-book']],
  ['money', ['far', 'fa-dollar-sign']], // dollar
  ['location-alt', ['far', 'fa-map-marker-alt']],
  ['paperclip', ['far', 'fa-paperclip']],
  ['self-pay', ['far', 'fa-hand-holding-usd']],
  ['wheelchair', ['far', 'fa-wheelchair']],
  ['calendar', ['far', 'fa-calendar-alt']],
  ['calendar-day', ['far', 'fa-calendar-day']],
  ['ellipsis', ['far', 'fa-ellipsis-h']],
  ['confirm-appointment', ['far', 'fa-calendar-check']],
  ['prescription', ['far', 'fa-file-prescription']],
  ['business', ['far', 'fa-briefcase']],
  ['verified', ['far', 'fa-shield-check']],
  ['stat', ['far', 'fa-exclamation-triangle']],
  ['filter', ['far', 'fa-filter']],
  ['save', ['far', 'fa-save']],
  ['undo', ['far', 'fa-undo']],
  ['view', ['far', 'fa-eye']],
  ['print', ['far', 'fa-print']],
  ['external-link', ['far', 'fa-external-link']],
  ['square-comment', ['far', 'fa-comment-alt-lines']],
  ['far-comment', ['far', 'fa-comment']],
  ['angle-right', ['far', 'fa-angle-right']],
  ['angle-double-right', ['far', 'fa-angle-double-right']],
  ['angle-down', ['far', 'fa-angle-down']],
  ['bookmark', ['far', 'fa-bookmark']],
  ['bars', ['far', 'fa-bars']],
  ['regular-edit', ['far', 'fa-pencil']],
  ['home-alt', ['far', 'fa-home-alt']],
  ['regular-user', ['far', 'fa-user']],
  ['question-circle', ['far', 'fa-question-circle']],
  ['check', ['far', 'fa-check']],
  ['trash-alt', ['far', 'fa-trash-alt']],
  ['search', ['far', 'fa-search']],
  ['level-up', ['far', 'fa-level-up-alt']],
  ['switch', ['far', 'fa-people-arrows']],
  ['clipboard-plus', ['far', 'fa-notes-medical']],
  ['clock', ['far', 'fa-clock']],
  ['file-signature', ['far', 'fa-file-signature']],
  ['file-pdf', ['far', 'fa-file-pdf']],
  ['calendar-exclamation', ['far', 'fa-calendar-exclamation']],
  ['file-excel', ['far', 'fa-file-excel']],
  ['receipt', ['far', 'fa-receipt']],
  ['sms', ['far', 'fa-sms']],
  ['arrow-down', ['far', 'fa-arrow-down']],
  ['arrow-up', ['far', 'fa-arrow-up']],
  ['arrow-right', ['far', 'fa-arrow-right']],
  ['arrow-left', ['far', 'fa-arrow-left']],
  ['sync', ['far', 'fa-sync-alt']],
  ['redo', ['far', 'fa-redo-alt']],
  ['copy', ['far', 'fa-copy']],
  ['angle-up', ['far', 'fa-angle-up']],
  ['chevron-up', ['far', 'fa-chevron-up']],
  ['chevron-down', ['far', 'fa-chevron-down']],
  ['chevron-double-down', ['far', 'fa-chevron-double-down']],
  ['chevron-double-up', ['far', 'fa-chevron-double-up']],
  ['note', ['far', 'fa-sticky-note']],
  ['info', ['fa-regular', 'fa-info']],
  ['credit-card', ['fa-regular', 'fa-credit-card']],
  ['close', ['fa-regular', 'fa-xmark']],
  ['clipboard-checklist-outline', ['fa-regular', 'fa-clipboard-list-check']],
  ['x-ray-regular', ['fa-regular', 'fa-x-ray']],
  ['book-open', ['fa-regular', 'fa-book-open']],

  // Light
  ['calendar-cancel', ['fal', 'fa-calendar-minus']],
  ['no-verified', ['fal', 'fa-shield']],
  // ["times-circle", ["fal", "temes-circle"]],
  ['cancel', ['fal', 'fa-times']],
  ['table', ['fal', 'fa-table']],
  ['history', ['fal', 'fa-history']],
  ['cog', ['fal', 'fa-cog']],
  ['laptop-medical', ['fal', 'fa-laptop-medical']],
  ['addressbooks', ['fal', 'fa-address-book']],
  ['thumbtack', ['fal', 'fa-thumbtack']],
  ['exchange', ['fal', 'fa-exchange']],
  ['comment-lines', ['fal', 'fa-comment-lines']],
  ['edit-fa', ['fal', 'fa-edit']],
  ['invoice-dollar', ['fal', 'fa-file-invoice-dollar']],
  ['info-circle', ['fal', 'fa-info-circle']],
]);
